<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editWarehouse')  + ' ('+ this.payload.id + ')' : $t('common.form.title.createWarehouse')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <zw-select-group v-model="form.type"
                                         :options="getLagerTypesList()"
                                         name="type"
                                         :label-prefix="labelPrefix"
                                         value-field="id"
                                         text-field="name"
                        >
                        </zw-select-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.description"
                                        name="description"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-form-group :label="$t(labelPrefix +'sync_sources')">
                            <multiselect
                                v-model="form.sync_sources"
                                :options="getSources().map(source=>parseInt(source.id))"
                                :multiple="true"
                                :custom-label="opt => {return getSources().find(s=>s.id==opt) ? getSources().find(s=>s.id==opt).api_type : ''}"
                            >
                            </multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <zw-textarea-group v-model="form.address"
                                           name="address"
                                           :label-prefix="labelPrefix"
                        ></zw-textarea-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <zw-select-group v-model="form.enable_mail_send"
                                         name="enable_mail_send"
                                         :label-prefix="labelPrefix"
                                         :options="{
                                            0: $t('common.button.no'),
                                            1: $t('common.button.yes'),
                                         }"
                        ></zw-select-group>
                    </b-col>
                </b-row>
                <template v-if="form.enable_mail_send">
                    <template v-for="(mail_contact, index) in form.mail_contacts">
                        <b-row>
                            <b-col cols="4">
                                <zw-input-group v-model="mail_contact.name"
                                                name="mail_contact_name[]"
                                                :placeholder="$t(labelPrefix+'name')"
                                                :label-prefix="labelPrefix"
                                                :disableLabel=true
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="4">
                                <zw-input-group v-model="mail_contact.email"
                                                name="mail_contact_email[]"
                                                validate="email"
                                                :placeholder="$t(labelPrefix+'email')"
                                                :label-prefix="labelPrefix"
                                                type="email"
                                                :disableLabel=true
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <b-button @click="deleteMail(index)"
                                          variant="danger"
                                          :disabled="disableRemove"
                                          :title="$t('common.title.delete')"
                                          class="mr-2"
                                >
                                    <font-awesome-icon icon="trash"/>
                                </b-button>

                                <b-button v-if="index == Object.keys(form.mail_contacts).length - 1"
                                          @click="addMail()"
                                          variant="success"
                                          :disabled="disableRemove"
                                >
                                    <font-awesome-icon icon="plus"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </template>
                </template>

                <div v-if="form.type" class="mb-2">
                    <b-button v-b-toggle.collapse-1-inner
                              size="sm"
                              variant="info"
                    >
                        <font-awesome-icon icon="file-image"/>
                        {{ $t('common.button.barcode') }}
                    </b-button>
                    <b-collapse id="collapse-1-inner" class="mt-2">
                        <b-card>
                            <b-row>
                                <b-col v-for="(barcode,index) in form.barcodes"
                                       cols="2"
                                       :key="index"
                                >
                                    <a @click.prevent="$root.openDocument(barcode, 'barcodes')"
                                       :href="$root.getDocumentUrl(barcode, 'barcodes')"
                                       target="_blank"
                                    >
                                        {{ barcode }}
<!--                                        <img v-if="images[barcode]" :src="images[barcode]"/>-->
                                    </a>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-collapse>
                </div>

                <b-row>
                    <b-col cols="3">
                        <zw-select-group v-model="form.barcodes_per_row"
                                         :options="getLagerBarcodesPerRowList()"
                                         name="barcodes_per_row"
                                         :label-prefix="labelPrefix"
                                         validate="required"
                        >
                        </zw-select-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="6" class="text-sm-right">
                        <b-button
                            v-if="form.id"
                            @click="generateBarcode(form.id)"
                            block
                            variant="info"
                            class="mr-2"
                        >
                            <font-awesome-icon icon="file-pdf"/>
                            {{ $t('common.button.pdf_generate') }}
                        </b-button>
                    </b-col>
                    <b-col sm="6" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import axios from "axios";

export default {
    name: 'WarehouseModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultMailContact: {'name': '', 'email': ''},
            defaultForm: {
                name: '',
                value: '',
                mail_contacts: [
                    {'name': '', 'email': ''}
                ]
            },
            showBarcode: false,
            disableRemove: false,
            form: {},
            labelPrefix: 'settings.warehouse.label.',
            matchKeys: ['id'],
            images:{}
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getLocales', 'getDocuments']),
        ...mapGetters('Channel', ['getSources']),
        ...mapGetters('Warehouses', ['getWarehouse']),
        ...mapGetters('Category', ['getCategoryOptionsTree']),
        ...mapGetters('LagerTypes', ['getLagerTypesList', 'getLagerBarcodesPerRowList']),
        shown() {
            const store = this.$store.dispatch('Warehouses/fetchWarehouse', this.payload.id)
            const categories = this.$store.dispatch('Category/fetchOptionsTree')
            const lagerTypesList = this.$store.dispatch('LagerTypes/fetchLagerTypesList')
            const barcodesPerRowList = this.$store.dispatch('LagerTypes/fetchBarcodesPerRow')
            const channels = this.$store.dispatch('Channel/fetchSources')

            Promise.all([store, categories, lagerTypesList, channels, barcodesPerRowList])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getWarehouse()))
                    }

                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    this.$store.dispatch('Warehouses/saveWarehouse', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        deleteMail(delete_index) {
            let index = 0;
            let new_mail_contacts = [];
            for (let mail_contact of this.form.mail_contacts) {
                if (index != delete_index) {
                    new_mail_contacts.push(mail_contact);
                }
                index++;
            }
            if (new_mail_contacts.length == 0) {
                new_mail_contacts.push(this.defaultMailContact);
            }
            this.form.mail_contacts = new_mail_contacts;
        },
        addMail() {
            this.form.mail_contacts.push(this.defaultMailContact);
        },
        showHideBarcode() {
            this.showBarcode = !this.showBarcode;
        },
        generateBarcode(id) {
            // TODO: change to secured bar code
            window.open(window.apiUrl + '/generatebarcodes/' + id);
        },
        getBarcodeSrc(barcode) {
            return window.apiUrl + '/showbarcode/' + barcode;
        },
        getImageBlobUrl(name) {
            let url = null
            if (typeof this.images[name] == 'undefined') {
                axios.get(window.apiUrl + '/download/barcodes/' + name)
                    .then(response => {
                        function s2ab(s) {
                            let buf = new ArrayBuffer(s.length);
                            let view = new Uint8Array(buf);
                            for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                            return buf;
                        }

                        const blob = new Blob([s2ab(atob(response.data.content))], {type: response.data.type});
                        this.$set(this.images, name, window.URL.createObjectURL(blob))
                    })
            }
        },
    }
}
</script>